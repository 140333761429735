<template>
  <div class="main">
    <div class="content_left">
      <div class="top">
        <el-input placeholder="请输入"></el-input>
        <el-button @click="toShowAddType" class="create_btn">
          <i class="el-icon-plus"></i>
        </el-button>
      </div>
      <div class="productTypeBox">
        <div
          v-for="(item, index) in materialTypeList"
          :key="index"
          class="productTypeItem"
          :class="typeIndex == index ? 'typeActive' : ''"
          @click="typeClick(item.productTypeCode, index)"
        >
          <div>{{ item.productTypeName }}</div>
          <div>
            <i
              class="el-icon-edit typeBtn"
              @click.stop="toShowAddType(item)"
            ></i>
            <i
              class="el-icon-delete typeBtn"
              style="margin-left: 10px;"
              @click.stop="toDeleteType(item.id)"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div class="content_Right">
      <div class="name">安徽中亮新能源</div>
      <div class="title">
        <span class="titleValue">物料清单</span>
      </div>
      <div class="subtitle">
        <div>安徽中亮新能源-物料清单</div>
        <el-button type="primary" icon="el-icon-plus" @click="toShowAddMateria"
          >新增</el-button
        >
      </div>
      <div class="tableBox">
        <el-table
          :data="materialList"
          tooltip-effect="dark"
          style="width: 100%"
          border
          height="calc(100vh - 246px)"
        >
          <el-table-column label="序号" type="index" width="50" align="center">
          </el-table-column>
          <el-table-column prop="productName" label="物料名称" align="center">
          </el-table-column>
          <el-table-column prop="specs" label="规格/型号" align="center">
          </el-table-column>
          <el-table-column prop="brand" label="品牌" align="center">
          </el-table-column>
          <el-table-column prop="power" label="功率" align="center">
          </el-table-column>
          <el-table-column prop="unit" label="单位" align="center">
          </el-table-column>
          <el-table-column prop="品牌" label="操作" align="center">
            <template slot-scope="scope">
              <span @click="toShowAddMateria(scope.row)" style="color: #f49b31;"
                >编辑</span
              >
              <span
                @click="toDeleteMateria(scope.row.id)"
                style="color: #d6001d;margin-left: 10px;"
                >移除</span
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="新增物料类型"
      center
      width="500px"
      :visible.sync="addTypeVisible"
      :before-close="cancelAddType"
    >
      <el-row>
        <el-form :model="addTypeForm" label-width="130px" :rules="typeRules">
          <el-col>
            <el-form-item label="物料类型名称：" prop="productTypeName">
              <el-input
                v-model="addTypeForm.productTypeName"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAddType">取 消</el-button>
        <el-button type="primary" @click="toAddType">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="新增物料"
      center
      width="800px"
      :visible.sync="addMaterialVisible"
      :before-close="cancelAddMateria"
    >
      <el-row>
        <el-form
          :model="addMateriaForm"
          label-width="110px"
          :rules="materiaRules"
          ref="addMateriaForm"
        >
          <el-col :span="12">
            <el-form-item label="物料名称：" prop="productName">
              <el-input
                v-model="addMateriaForm.productName"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格/型号：" prop="specs">
              <el-input v-model="addMateriaForm.specs" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="品牌：" prop="brand">
              <el-input v-model="addMateriaForm.brand" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="功率：" prop="power">
              <el-input v-model="addMateriaForm.power" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单价：" prop="price">
              <el-input v-model="addMateriaForm.price" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位：" prop="unit">
              <el-input v-model="addMateriaForm.unit" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="物料类型：" prop="productTypeCode">
              <el-select
                v-model="addMateriaForm.productTypeCode"
                placeholder="请选择"
                ref="materialType"
              >
                <el-option
                  v-for="item in materialTypeOption"
                  :key="item.productTypeCode"
                  :label="item.productTypeName"
                  :value="item.productTypeCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAddMateria">取 消</el-button>
        <el-button type="primary" @click="toAddMateria">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      materialList: [],
      materialTypeList: [],
      materialTypeOption: [],
      queryInfo: {
        pageSize: 10,
        currPage: 1,
        condition: {
          agentCode: "",
          productTypeCode: ""
        }
      },
      total: 0,
      addTypeVisible: false,
      addTypeForm: {
        productTypeName: ""
      },
      typeRules: {
        productTypeName: [
          {
            required: true,
            message: "请输入物料类型名称",
            trigger: "blur"
          }
        ]
      },
      addMaterialVisible: false,
      addMateriaForm: {},
      materiaRules: {
        productName: [
          {
            required: true,
            message: "请输入物料名称",
            trigger: "blur"
          }
        ],
        specs: [
          {
            required: true,
            message: "请输入规格/型号",
            trigger: "blur"
          }
        ],
        brand: [
          {
            required: true,
            message: "请输入品牌",
            trigger: "blur"
          }
        ],
        power: [
          {
            required: true,
            message: "请输入功率",
            trigger: "blur"
          }
        ],
        price: [
          {
            required: true,
            message: "请输入单价",
            trigger: "blur"
          }
        ],
        unit: [
          {
            required: true,
            message: "请输入单位",
            trigger: "blur"
          }
        ],
        productTypeCode: [
          {
            required: true,
            message: "请选择物料类型",
            trigger: "change"
          }
        ]
      },
      typeIndex: -1
    };
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo")).userInfo;
    // this.queryInfo.condition.agentCode = userInfo.agentCode;
    this.getMaterialTypeList();
    this.getList();
  },
  methods: {
    getMaterialTypeList() {
      // let agentCode = JSON.parse(localStorage.getItem("userInfo")).userInfo
      //   .agentCode;
      this.$http
        .post("houseAgentProductType/queryList", {
          // agentCode
        })
        .then(res => {
          if (res.data.code == 200) {
            this.materialTypeList = res.data.data;
          }
        });
    },
    getMaterialTypeOption() {
      // let agentCode = JSON.parse(localStorage.getItem("userInfo")).userInfo
      //   .agentCode;
      this.$http
        .post("houseAgentProductType/queryList", {
          // agentCode
        })
        .then(res => {
          console.log(res,'物料类型')
          if (res.data.code == 200) {
            this.materialTypeOption = res.data.data;
          }
        });
    },
    getList() {
      this.$http.post("houseAgentProduct/list", this.queryInfo).then(res => {
        if (res.data.code == 200) {
          this.materialList = res.data.data.data;
          this.total = res.data.data.count;
        }
      });
    },
    typeClick(code, index) {
      this.queryInfo.condition.productTypeCode = code;
      this.typeIndex = index;
      this.getList();
    },
    toShowAddType(val) {
      if (val.id) {
        this.addTypeForm = val;
      } else {
        this.addTypeForm = {
          productTypeName: ""
        };
      }

      this.addTypeVisible = true;
    },
    toAddType() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo")).userInfo;
      if (this.addTypeForm.id) {
        this.addTypeForm.updateCode = userInfo.userCode;
        this.addTypeForm.updateName = userInfo.name;
      } else {
        this.addTypeForm.createCode = userInfo.userCode;
        this.addTypeForm.createName = userInfo.name;
      }
      this.addTypeForm.agentCode = userInfo.agentCode;
      this.addTypeForm.agentName = userInfo.agentName;

      this.$http
        .post("houseAgentProductType/save", this.addTypeForm)
        .then(res => {
          if (res.data.code == 200) {
            this.$message.success("操作成功！");
            this.getMaterialTypeList();
            this.addTypeVisible = false;
          }
        });
    },
    cancelAddType() {
      this.addTypeVisible = false;
      this.addTypeForm = {
        productTypeName: ""
      };
    },
    cancelAddMateria() {
      this.addMaterialVisible = false;
      this.addMateriaForm = {};
    },
    toDeleteType(id) {
      this.$confirm("确定删除此物料类型吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$http
          .post("houseAgentProductType/delete", {
            id
          })
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success("删除成功！");
              this.getMaterialTypeList();
            } else {
              this.$message.warning(res.data.message);
            }
          });
      });
    },
    toShowAddMateria(val) {
      if (val.id) {
        this.addMateriaForm = val;
      } else {
        this.addMateriaForm = {};
      }
      this.addMaterialVisible = true;
      this.getMaterialTypeOption();
    },
    toAddMateria() {
      this.$refs["addMateriaForm"].validate(valid => {
        if (valid) {
          let userInfo = JSON.parse(localStorage.getItem("userInfo")).userInfo;
          if (this.addMateriaForm.id) {
            this.addMateriaForm.updateCode = userInfo.userCode;
            this.addMateriaForm.updateName = userInfo.name;
          } else {
            this.addMateriaForm.createCode = userInfo.userCode;
            this.addMateriaForm.createName = userInfo.name;
          }
          this.addMateriaForm.agentCode = userInfo.agentCode;
          this.addMateriaForm.agentName = userInfo.agentName;
          this.addMateriaForm.productTypeName = this.$refs.materialType.selected.label;

          this.$http
            .post("houseAgentProduct/save", this.addMateriaForm)
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success("操作成功！");
                this.getList();
                this.addMaterialVisible = false;
              } else {
                this.$message.warning(res.data.message);
              }
            });
        } else {
          return false;
        }
      });
    },
    toDeleteMateria(id) {
      this.$confirm("确定删除此物料吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$http
          .post("houseAgentProduct/delete", {
            id
          })
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success("删除成功！");
              this.getList();
            } else {
              this.$message.warning(res.data.message);
            }
          });
      });
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
      this.addTypeVisible = false;
    }
  }
};
</script>
<style lang="less" scoped>
.main {
  display: flex;
  height: calc(100vh - 80px);
  gap: 7px;
  .content_left {
    width: 280px;
    flex-shrink: 0;
    height: 100%;
    background-color: #ffffff;

    .top {
      display: flex;
      padding: 7px;
      /deep/ .el-input__inner {
        height: 30px !important;
        line-height: 30px !important;
      }
      .el-button {
        height: 32px;
        border-radius: 6px;
      }
      .create_btn {
        background: #02a7f0;
        margin-left: 12px;
        i {
          font-size: 18px;
          font-weight: 700;
          color: #fff;
        }
      }
    }
  }

  .content_Right {
    flex: 1;
    background-color: #ffffff;
    height: 100%;
    .name {
      padding: 10px 20px 7px;
      font-size: 18px;
    }
    .title {
      width: 100%;
      border-bottom: 2px solid #f2f2f2;
      color: #02a7f0;
      font-size: 14px;
      font-weight: bold;
      padding-left: 20px;
      padding-bottom: 3px;
      .titleValue {
        position: relative;
      }
      .titleValue::after {
        display: block;
        content: "";
        width: 100%;
        height: 3px;
        background-color: #02a7f0;
        position: absolute;
        bottom: -5px;
        border-radius: 10px;
      }
    }
    .subtitle {
      font-size: 12px;
      color: #02a7f0;
      margin: 7px 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.productTypeBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
}
.productTypeItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 30px;
  background-color: #f2f2f2;
}
.productTypeItem:hover {
  background-color: #b2e4fb;
}
.typeActive {
  background-color: #b2e4fb;
}
.tableBox {
  padding: 0 20px;
}
.typeBtn {
  cursor: pointer;
}
.typeBtn:hover {
  color: red;
}
</style>
